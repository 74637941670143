import { get, post, deletefn } from './api'
import axios from "axios";

// 登录封装
export const login = p => post('/login', p)

// 会员信息
// 获取会员列表
export const getAllCompanyList = p => get('/company/getAllCompanyList', p)

//设置后台登录密码

export const setPassword = p => post('/account/setPassword', p)

//删除会员信息     

export const delCompanyInfo = p => post('/company/delCompanyInfo', p)

//添加会员信息
export const addCompanyInfo = p => post('/company/addCompanyInfo', p)

//编辑会员信息

export const modifyCompanyInfo = p => post('/company/modifyCompanyInfo', p)

// 设置会员上下架
export const setCompanyInfoStatus = p => post('/company/setCompanyInfoStatus', p)

//会员续费
export const renewCompany = p => post('/company/renewCompany', p)


//会员申请

//获取会员列表

export const getCompanyApplyList = p => get('/companyApply/getCompanyApplyList', p)

//删除会员申请
export const delCompanyApply = p => post('/companyApply/delCompanyApply', p)

//处理会员申请
export const dealCompanyApply = p => post('/companyApply/dealCompanyApply', p)

//会员账户
//获取会员账户列表

export const getCompanyAccounts = p => get('/comAccount/getCompanyAccounts', p)
//新增公司子账号

export const addAccount = p => post('/comAccount/addAccount', p)
//会员账号解绑

export const unboundAccount = p => post('/comAccount/unboundAccount', p)
//删除会员账号信息

export const delAccount = p => post('/comAccount/delAccount', p)

//编辑会员账号信息

export const modifyAccount = p => post('/comAccount/modifyAccount', p)

// 会员卡片
//获取会员卡片列表
export const getCompanyCardList = p => get('/businessCard/getCompanyCardList', p)

//添加卡片
export const createCompanyCard = p => post('/businessCard/createCompanyCard', p)

//编辑卡片
export const modifyCompanyCard = p => post('/businessCard/modifyCompanyCard', p)

//删除卡片
export const delCard = p => post('/businessCard/delCard', p)

//会员服务
//获取服务列表
export const getServiceList = p => get('/serviceMsg/getServiceList', p)

//添加服务
export const addServiceMsg = p => post('/serviceMsg/addServiceMsg', p)

//编辑服务
export const updateServiceMsg = p => post('/serviceMsg/updateServiceMsg', p)

//删除服务
export const delServiceMsg = p => post('/serviceMsg/delServiceMsg', p)

//通知联络人
//获取
export const getNoticePersonList = p => get('/companyApply/getNoticePersonList', p)

//添加
export const addNoticePerson = p => post('/companyApply/addNoticePerson', p)

//删除
export const delNoticePerson = p => post('/companyApply/delNoticePerson', p)



//轮播图--------------------------------------------------------------------------
// 获取轮播图列表
export const getHomeRotationInfoList = p => get('/rotation/getHomeRotationInfoList', p)

// 新增轮播图
export const addRotation = p => post('/rotation/addRotation', p)

// 修改轮播图
export const modifyRotation = p => post('/rotation/modifyRotation', p)

// 删除轮播图
export const delRotation = p => post('/rotation/delRotation', p)

//pdf
// 查询所有pdf
export const getPDFInfoList = p => get('/PDFMsg/getPDFInfoList', p)

// 新增pdf
export const addPDFInfo = p => post('/PDFMsg/addPDFInfo', p)

// 修改pdf
export const modifyPDFInfo = p => post('/PDFMsg/modifyPDFInfo', p)

// 删除pdf
export const delPDFInfo = p => post('/PDFMsg/delPDFInfo', p)

//批量删除pdf

export const delPDFInfos = p => post('/PDFMsg/delPDFInfos', p)

//设置上下架
export const setPDFInfoStatus = p => post('/PDFMsg/setPDFInfoStatus', p)

//推荐pdf

//获取推荐文档列表
export const getRecPDFList = p => get('/PDFMsg/getRecPDFList', p)

//设置推荐PDF

export const setPDFInfoRecStatus = p => post('/PDFMsg/setPDFInfoRecStatus', p)

// 获取wx用户列表
export const getUserList = p => get('/userInfo/getUserList', p)

// 设置小程序用户拉黑

export const setUserStatus = p => post('/userInfo/setUserStatus', p)
//资讯
//添加资讯  
export const addArticle = p => post('/articleInfo/addArticle', p)

//删除资讯

export const delArticle = p => post('/articleInfo/delArticle', p)

//批量删除资讯
export const delArticles = p => post('/articleInfo/delArticles', p)

//编辑资讯

export const modifyArticle = p => post('/articleInfo/modifyArticle', p)

//设置上下架

export const setArticleStatus = p => post('/articleInfo/setArticleStatus', p)

//获取资讯列表

export const getArticles = p => get('/articleInfo/getArticles', p)

//分类
//按分组获取分类
export const getTypes = p => get('/cmsType/getTypes', p)

//添加分类
export const addType = p => post('/cmsType/addType', p)

//编辑分类
export const modifyType = p => post('/cmsType/modifyType', p)

//删除分类
export const delType = p => post('/cmsType/delType', p)

//设置分类上下架
export const setTypeStatus = p => post('/cmsType/setTypeStatus', p)

//系统内容 

//通用文本信息

//按模块获取通用文本信息
export const getCommonMsgByModuleName = p => get('/commonMsg/getCommonMsgByModuleName', p)

//按分类获取通用文本信息
export const getCommonMsgByTypeId = p => get('/commonMsg/getCommonMsgByTypeId', p)

//获取通用文本信息
export const getCommonMsgList = p => get('/commonMsg/getCommonMsgList', p)

//编辑通用文本信息
export const modifyCommonMsg = p => post('/commonMsg/modifyCommonMsg', p)

//删除通用文本信息
export const delCommonMsg = p => post('/commonMsg/delCommonMsg', p)

//添加通用文本信息
export const addCommonMsg = p => post('/commonMsg/addCommonMsg', p)

//联系方式
//获取联系方式
export const getContactMsg = p => get('/contactMsg/getContactMsg', p)

//清除联系方式
export const clearContactMsg = p => post('/contactMsg/clearContactMsg', p)

//保存联系方式
export const saveContactMsg = p => post('/contactMsg/saveContactMsg', p)


// 操作日志
export const getLogList = p => get('/operLog/getLogList', p)

//七牛云
export const getUploadToken = p => get('/file/getUploadToken', p)

//数据概要
export const getOverviewData = p => get('/sys/getOverviewData', p)

//重置密码
export const resetAccountPassword = p => post('/comAccount/resetAccountPassword', p)

//添加活动
export const addActivity = p => post('/activity/addActivity', p)

//编辑活动
export const updateActivity = p => post('/activity/updateActivity', p)

//删除活动
export const delActivity = p => post('/activity/delActivity', p)

//设置活动
export const setActivityStatus = p => post('/activity/setActivityStatus', p)

//获取活动
export const getActivityList = p => get('/activity/getActivityList', p)

//获取活动用户信息列表
export const getSignInfoList = p => get('/activity/getSignInfoList', p)

//编辑活动用户信息
export const updateSignInfo = p => post('/activity/updateSignInfo', p)

//删除活动用户信息
export const delSignInfo = p => post('/activity/delSignInfo', p)


//添加平台管理员
export const addManagerUser = p => post('/manager/addManagerUser', p)

//编辑平台管理员
export const updateManagerUser = p => post('/manager/updateManagerUser', p)

//删除平台管理员
export const delManagerUser = p => post('/manager/delManagerUser', p)

//重置平台管理员
export const resetManagerPassword = p => post('/manager/resetManagerPassword', p)

//获取平台管理员
export const getManagerUserList = p => get('/manager/getManagerUserList', p)



//获取相册
export const getAlbumList = p => get('/album/getAlbumList', p)

//添加相册
export const addAlbum = p => post('/album/addAlbum', p)

//编辑相册
export const updateAlbum = p => post('/album/updateAlbum', p)

//删除相册
export const delAlbum = p => post('/album/delAlbum', p)

//上下架相册
export const setAlbumStatus = p => post('/album/setAlbumStatus', p)

//获取相册文件列表
export const getAlbumFileList = p => get('/album/getAlbumFileList', p)

//添加文件
export const addAlbumFile = p => post('/album/addAlbumFile', p)

//删除文件
export const delAlbumFile = p => post('/album/delAlbumFile', p)



// ----------------------------

// 专题管理
// 获取专题
export const getTopics = p => get('/topic/getTopics', p)

// 添加专题
export const addTopic = p => post('/topic/addTopic', p)

// 删除专题
export const delTopic = p => post('/topic/delTopic', p)

// 编辑专题
export const updateTopic = p => post('/topic/updateTopic', p)


// 获取专题分类列表
export const getCategories = p => get('/topicCategory/getCategories', p)

// 添加专题分类信息
export const topicCategory = p => post('/topicCategory', p)


// 删除专题分类信息
export function delTopicCategory(categoryId) {
    return axios({
        url: `/topicCategory/${categoryId}`,
        method: 'delete'
    })
}


// 编辑专题分类信息
export function modifyTopicCategory(data) {
    return axios({
        url: `/topicCategory/${data.categoryId}`,
        method: 'put',
        data
    })
}


// 设置为轮播图
export function setPics(topicId) {
    return axios({
        url: `/topic/setPics/${topicId}`,
        method: 'get',
    })
}

// 取消轮播图
export function delPics(topicId) {
    return axios({
        url: `/topic/delPics/${topicId}`,
        method: 'get',
    })
}




// 获取被邀请注册用户列表
export const getInvitedUserList = p => get('/userInfo/getInvitedUserList', p)

// 获取浏览记录
export const getViewRecords = p => get('/viewRecord/getViewRecords', p)

// 查看全部分享记录
export const getShareList = p => get('/share/getShareList', p)

// 查看分享被浏览记录列表
export const getShareBrowseList = p => get('/share/getShareBrowseList', p)



import {
  getToken,
  setToken,
  removeToken,
  setTimeStamp
} from '@/util/auth'
import {
  login,
} from '@/request/http'

const state = {
  token: getToken(),
  userInfo: {}
}
const mutations = {
  setToken(state, token) {
    state.token = token // 将数据设置给vuex
    setToken(token) // 同步给缓存
  },
  removeToken(state) {
    state.token = null // 将vuex的数据置空
    removeToken() // 清除缓存
  },
  setUserInfo(state, result) {
    state.userInfo = result
  },
  removeUserInfo(state) {
    state.userInfo = {}
  }

}
const actions = {
  async login(context, res) {
    // 经过响应拦截器的处理之后 这里的result实际上就是 token
    const {
      data
    } = await login(res) // 实际上就是一个promise  result就是执行的结果
    // console.log(data)
    // axios默认给数据加了一层data
    // 表示登录接口调用成功 也就是意味着你的用户名和密码是正确的
    // 现在有用户token
    // actions 修改state 必须通过mutations
    localStorage.setItem('role', data.userRole)
    context.commit('setToken', data.token) // 设置token
    context.commit('setUserInfo', data)
    console.log(state.userInfo);
    setTimeStamp() // 设置时间戳
  },

  // 登出的action
  logout(context) {
    // 删除token
    context.commit('removeToken') // 
    context.commit('removeUserInfo') // 删除用户信息
    localStorage.removeItem('menuList')
    localStorage.removeItem('role')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
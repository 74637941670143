import Vue from "vue";
import Router from "vue-router";
import Layout from "../layout/layout.vue";
// 使用VueRouter
Vue.use(Router);

//获取原型对象上的push函数
// const originalPush = Router.prototype.push;
// Router.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch((err) => err);
// };
// const originalReplace = Router.prototype.replace;

// Router.prototype.replace = function replace (location, onResolve, onReject) {
//   // if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
//   return originalReplace.call(this, location).catch(err => err)
// }
export const constantRoutes = [
  // 登录页

  { path: '/', redirect: '/login' },
  { path: '/login', name: 'login', component: () => import('../init/login.vue') },

  {
    path: "/Overview",
    meta: { name: "首页", icon: "dashboard" },
    component: Layout,
    children: [
      {
        path: "",
        meta: { name: "数据概览", icon: "excel" },
        component: () => import('../views/Overview/Overview.vue')
      },

      {
        path: "/managementpdf",
        hidden: true,
        meta: { name: "PDF分类管理", icon: "el-icon-s-home" },
        component: () => import('../views/class/pdf.vue')

      },
      {
        path: "/managementarticle",
        hidden: true,
        meta: { name: "资讯分类管理", icon: "el-icon-s-home" },
        component: () => import('../views/class/article.vue')
      },
      {
        path: "/managementabout",
        hidden: true,
        meta: { name: "关于我们分类管理", icon: "el-icon-s-home" },
        component: () => import('../views/class/about.vue')
      },

      // 浏览记录
      {
        path: "/browse",
        hidden: true,
        meta: { name: "浏览记录", icon: "el-icon-s-home" },
        component: () => import('../views/content/browse.vue')
      },

      // 分享记录
      {
        path: "/share",
        hidden: true,
        meta: { name: "分享记录", icon: "el-icon-s-home" },
        component: () => import('../views/content/share.vue')
      },


      // 查看分享被浏览记录列表
      {
        path: "/beView",
        hidden: true,
        meta: { name: "分享被浏览记录", icon: "el-icon-s-home" },
        component: () => import('../views/content/beView.vue')
      },
      // {
      //   path: "/pdftopics",
      //   hidden: true,
      //   meta: { name: "PDF专题管理", icon: "el-icon-s-home" },
      //   component: () => import('../views/class/pdftopics.vue')
      // },
      // {
      //   path: "/articletopics",
      //   hidden: true,
      //   meta: { name: "新闻咨询专题管理", icon: "el-icon-s-home" },
      //   component: () => import('../views/class/articletopics.vue')
      // },
      {
        path: "/addaboutus",
        hidden: true,
        component: () => import("../views/content/addzx.vue")
      },
      {
        path: "/addmessage",
        hidden: true,
        component: () => import('../views/system/addmessage.vue')

      },
      {
        path: "/addactivity",
        hidden: true,
        component: () => import('../views/activity/addactivity.vue')
      },
      {
        path: "/addfile",
        hidden: true,
        component: () => import('../views/photo/addfile.vue')
      },
      {
        path: "/addphotos",
        hidden: true,
        component: () => import('../views/photo/addphotos.vue')
      },
      {
        path: "/addvideo",
        hidden: true,
        component: () => import('../views/photo/addvideo.vue')
      },
      {
        path: "/file",
        hidden: true,
        meta: { name: "文件列表", icon: "el-icon-s-home" },
        component: () => import('../views/photo/file.vue')
      },

      {
        path: "/activityUser",
        hidden: true,
        meta: { name: "活动报名列表", icon: "el-icon-s-home" },
        component: () => import('../views/activity/member.vue')
      },

      {
        path: "/invitation",
        hidden: true,
        meta: { name: "邀请注册用户", icon: "peoples" },
        component: () => import('../views/user/invitation.vue')
      },
    ]
  },

  {
    path: "/swiper",

    meta: { name: "内容管理", icon: "message" },
    component: Layout,
    children: [
      {
        path: "",
        meta: { name: "首页轮播", icon: "skill" },
        component: () => import('../views/content/swiper.vue')
      },

      {
        path: "/pdfClassify",
        meta: { name: "PDF", icon: "pdf" },
        component: () => import("../views/content/pdf.vue")
      },
      {
        path: "/recomendpdf",
        meta: { name: "推荐PDF", icon: "tab" },
        component: () => import("../views/content/rpdf.vue")
      },


      {
        path: "/aboutus",
        meta: { name: "资讯", icon: "lock" },
        component: () => import('../views/content/information.vue')
      },
    ]
  },
  {
    path: "/photo",
    meta: { name: "相册管理", icon: "zip" },
    component: Layout,
    children: [
      {
        path: "",
        meta: { name: "相册列表", icon: "zip" },
        component: () => import('../views/photo/photos.vue')
      },
    ]
  },
  {
    path: "/activity",
    meta: { name: "活动管理", icon: "form" },
    component: Layout,
    children: [
      {
        path: "",
        meta: { name: "活动管理", icon: "form" },
        component: () => import('../views/activity/activity.vue')
      },
    ]
  },

  {
    path: "/managementUser",
    meta: { name: "平台管理", icon: "user" },
    component: Layout,
    children: [
      {
        path: "",
        meta: { name: "管理员列表", icon: "user" },
        component: () => import('../views/user/management.vue')
      },
    ]
  },
  {
    path: "/rotationChart",
    meta: { name: "人员管理", icon: "people" },
    component: Layout,
    children: [
      {
        path: "",
        meta: { name: "用户管理", icon: "peoples" },
        component: () => import('../views/user/rotationChart.vue')
      },

    ]
  },

  {
    path: "/message",
    meta: { name: "系统内容", icon: "skill" },
    component: Layout,
    children: [
      {
        path: "",
        meta: { name: "关于我们", icon: "component" },
        component: () => import('../views/system/message.vue')
      },

      {
        path: "/concat",
        meta: { name: "联系方式", icon: "wechat" },
        component: () => import('../views/system/contact.vue')
      },


    ]
  },
  {
    path: "/ulog",
    meta: { name: "操作日志", icon: "setting" },
    component: Layout,
    children: [
      {
        path: "",
        meta: { name: "操作日志", icon: "list" },
        component: () => import('../views/ULog/ULog.vue')
      },
    ]
  },

  // 专题管理
  {
    path: "/specialTopic",
    meta: { name: "专题管理", icon: "setting" },
    component: Layout,
    children: [
      {
        path: "",
        meta: { name: "专题", icon: "list" },
        component: () => import('../views/specialTopic/index.vue')
      },

      // 专题分类
      {
        path: "/classify",
        hidden: true,
        meta: { name: "专题分类", icon: "list" },
        component: () => import('../views/specialTopic/classify.vue')
      },

    ]
  },


  // 全部分享记录
  {
    path: "/allShare",
    meta: { name: "分享记录", icon: "setting" },
    component: Layout,
    children: [
      {
        path: "",
        meta: { name: "全部分享记录", icon: "list" },
        component: () => import('../views/allShare/index.vue')
      },
    ]
  },


];

export default new Router({
  mode: "hash",
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes,
});

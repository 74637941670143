import axios from "axios";
import Cookies from 'js-cookie'
import store from '../store'
import {
  getTimeStamp
} from '../util/auth.js'
import router from '../router'
// import QS from 'qs'
// 引入进度条插件
import { Message, MessageBox } from "element-ui";
const TimeOut = 86400 // 定义超时时间86400
axios.defaults.timeout = 3000;

// axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8'

axios.defaults.baseURL = 'http://web.innopinenut.com/web'
// axios.defaults.baseURL = 'http://192.168.2.164:8892/web';


// 请求拦截器
axios.interceptors.request.use(
  config => {
    if (store.getters.token) {
      // config.headers['Authorization'] = 'Bearer ' + store.getters.token
      // 有token 判断token是否超时
      if (IsCheckTimeOut()) {
        // 退出登录
        store.dispatch('user/logout')
        // 跳转到登录页面tangyan
        router.push('/login')
        return Promise.reject(new Error('token超时'))
      }
      config.headers.token = store.getters.token
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }



);

// 接收响应拦截器
axios.interceptors.response.use(
  (response) => {

    return response;
  },
  // 被动处理token失效

  (error) => {
    if (error.response.data == 5) {
      // token 超时，登出，跳转
      // 退出登录
      store.dispatch('user/logout')
      // 跳转到登录页面
      router.push('/login')
    } else {
      Message.error(error.message)
    }
    return Promise.reject(error)

  }
);


// get方法，对应get请求
export function get(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
}

//  post方法，对应post请求
export function post(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, params)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
}
function IsCheckTimeOut() {
  // 当前时间戳
  var currentTime = Date.now()
  // 缓存中的时间戳
  var timeStamp = getTimeStamp()
  // 时间差
  console.log((currentTime - timeStamp) / 1000);
  return (currentTime - timeStamp) / 1000 > TimeOut
}
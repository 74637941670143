const getters = {
  // userName: state => state.user.userInfo.userName,
  token: state => state.user.token,
  userInfo:state=>state.user.userInfo,
  role:state=>state.user.userInfo.userRole,
  isCollapse:state => state.setting.isCollapse,
  // menuList:state => state.user.menuList,
  menuList:state => state.permission.menuList,
  itemList:state => state.mainData.itemList,
  activeRoute:state => state.mainData.activeRoute,
  breadList:state => state.mainData.breadList,
}
export default getters
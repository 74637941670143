// vuex的权限模块
// import { asyncRoutes, constantRoutes } from '@/router'
import Layout from "@/layout/layout.vue";
import store from '@/store'

// vuex中的permission模块用来存放当前的 静态路由 + 当前用户的 权限路由
var rtroutes = [
  {
    path: "/Overview",
    meta: { name: "首页", icon: "dashboard" },
    component: "Layout",
    children: [
      {
        path: "/Overview",
        meta: { name: "数据概览", icon: "excel" },
        component: "Layout",
      },
    ]
  },

  {
    path: "/swiper",

    meta: { name: "内容管理", icon: "message" },
    component: Layout,
    children: [

      {
        path: "/aboutus",
        meta: { name: "资讯", icon: "lock" },
        component: "Layout",
      },
      {
        path: "/swiper",
        meta: { name: "首页轮播", icon: "skill" },
        component: "Layout",
      },

      {
        path: "/pdfClassify",
        meta: { name: "PDF", icon: "pdf" },
        component: "Layout",
      },


    ]
  },


  // 专题管理
  {
    path: "/specialTopic",
    meta: { name: "专题管理", icon: "setting" },
    component: "Layout",
    children: [
      {
        path: "/specialTopic",
        meta: { name: "专题", icon: "list" },
        component: "Layout",
      },


    ]
  },


  {
    path: "/photo",
    meta: { name: "相册管理", icon: "zip" },
    component: Layout,
    children: [
      {
        path: "/photo",
        meta: { name: "相册列表", icon: "zip" },
        component: "Layout",
      },
    ]
  },
  {
    path: "/activity",
    meta: { name: "活动管理", icon: "form" },
    component: Layout,
    children: [
      {
        path: "/activity",
        meta: { name: "活动管理", icon: "form" },
        component: "Layout",
      },
    ]
  },
  {
    path: "/managementUser",
    meta: { name: "平台管理", icon: "user" },
    component: Layout,
    children: [
      {
        path: "/managementUser",
        meta: { name: "管理员列表", icon: "user" },
        component: "Layout"
      },
    ]
  },
  {
    path: "/rotationChart",
    meta: { name: "人员管理", icon: "people" },
    component: "Layout",
    children: [
      {
        path: "/rotationChart",
        meta: { name: "用户管理", icon: "peoples" },
        component: "Layout",
      },

    ]
  },

  // 全部分享记录
  {
    path: "/allShare",
    meta: { name: "分享记录", icon: "skill" },
    component: "Layout",
    children: [
      {
        path: "/allShare",
        meta: { name: "分享记录", icon: "component" },
        component: "Layout",
      },

    ]
  },



  {
    path: "/message",
    meta: { name: "系统内容", icon: "skill" },
    component: "Layout",
    children: [
      {
        path: "/message",
        meta: { name: "关于我们", icon: "component" },
        component: "Layout",
      },
      {
        path: "/concat",
        meta: { name: "联系方式", icon: "wechat" },
        component: "Layout",
      },


    ]
  },
  {
    path: "/ulog",
    meta: { name: "操作日志", icon: "setting" },
    component: "Layout",
    children: [
      {
        path: "/ulog",
        meta: { name: "操作日志", icon: "list" },
        component: "Layout",
      },
    ]
  },







]
var rroutes = [{
  path: "/Overview",
  meta: { name: "首页", icon: "dashboard" },
  component: "Layout",
  children: [
    {
      path: "/Overview",
      meta: { name: "数据概览", icon: "excel" },
      component: "Layout",
    },
  ]
}
  ,

{
  path: "/swiper",

  meta: { name: "内容管理", icon: "message" },
  component: Layout,
  children: [

    {
      path: "/aboutus",
      meta: { name: "资讯", icon: "lock" },
      component: "Layout",
    },
    {
      path: "/swiper",
      meta: { name: "首页轮播", icon: "skill" },
      component: "Layout",
    },

    {
      path: "/pdfClassify",
      meta: { name: "PDF", icon: "pdf" },
      component: "Layout",
    },



  ]
},

// 专题管理
{
  path: "/specialTopic",
  meta: { name: "专题管理", icon: "setting" },
  component: "Layout",
  children: [
    {
      path: "/specialTopic",
      meta: { name: "专题", icon: "list" },
      component: "Layout",
    },

  ]
},



{
  path: "/photo",
  meta: { name: "相册管理", icon: "zip" },
  component: Layout,
  children: [
    {
      path: "/photo",
      meta: { name: "相册列表", icon: "zip" },
      component: "Layout",
    },
  ]
},
{
  path: "/activity",
  meta: { name: "活动管理", icon: "form" },
  component: Layout,
  children: [
    {
      path: "/activity",
      meta: { name: "活动管理", icon: "form" },
      component: "Layout",
    },
  ]
},

{
  path: "/rotationChart",
  meta: { name: "人员管理", icon: "people" },
  component: "Layout",
  children: [
    {
      path: "/rotationChart",
      meta: { name: "用户管理", icon: "peoples" },
      component: "Layout",
    },

  ]
},

// 全部分享记录
{
  path: "/allShare",
  meta: { name: "分享记录", icon: "skill" },
  component: "Layout",
  children: [
    {
      path: "/allShare",
      meta: { name: "分享记录", icon: "component" },
      component: "Layout",
    },

  ]
},

{
  path: "/message",
  meta: { name: "系统内容", icon: "skill" },
  component: "Layout",
  children: [
    {
      path: "/message",
      meta: { name: "关于我们", icon: "component" },
      component: "Layout",
    },
    {
      path: "/concat",
      meta: { name: "联系方式", icon: "wechat" },
      component: "Layout",
    },


  ]
},




]


const state = {

  menuList: null // 所有人默认拥有静态路由
}
const mutations = {
  // newRoutes可以认为是 用户登录 通过权限所得到的动态路由的部分
  setRoutes(state, Routes) {
    // 应该是每次更新 都应该在静态路由的基础上进行追加
    state.menuList = [...Routes,]
  }
}
const actions = {

  async getRoutes(context) {
    console.log(localStorage.getItem('role'));
    // context.commit('setRoutes', rroutes)
    // return menuList
    if (localStorage.getItem('role') == 0) {
      context.commit('setRoutes', rtroutes)

      localStorage.setItem('menuList', JSON.stringify(rtroutes))
      return state.menuList
    }
    else if (localStorage.getItem('role') == 1) {
      context.commit('setRoutes', rroutes)
      localStorage.setItem('menuList', JSON.stringify(rroutes))
      return state.menuList
    }
  }
}


export default {
  namespaced: true,
  state,
  mutations,
  actions
}